import { Computed, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { State } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { TeacherInterface } from '@state/current-user/current-user.interfaces';
import { Observable, tap } from 'rxjs';

export interface UsersStateInterface {
  users: TeacherInterface[];
  search: string;
  roles: number[];
}

@Persistence()
@StateRepository()
@State<UsersStateInterface>({
  name: 'users',
  defaults: {
    users: [],
    search: '',
    roles: [],
  },
})
@Injectable({ providedIn: 'any' })
@UntilDestroy()
export class UsersState extends NgxsDataRepository<UsersStateInterface> {
  constructor(private httpClient: HttpClient) {
    super();
  }

  @Computed()
  get users(): TeacherInterface[] {
    return this.snapshot?.users;
  }

  getUsers(): Observable<TeacherInterface[]> {
    return this.httpClient.get<TeacherInterface[]>('{api}/panel/users');
  }

  getFilteredUsers(s: string = '', role: number[] = []): Observable<TeacherInterface[]> {
    let params = new HttpParams();
    if (s.length > 0) {
      params = params.set('search', s);
    }
    if (role.length > 0) {
      const roles = role.join(',');
      params = params.set('role', roles);
    }
    return this.httpClient.get<TeacherInterface[]>('{api}/panel/users/search', { params }).pipe(
      tap((users: TeacherInterface[]) => {
        this.ctx.setState((state) => ({
          ...state,
          users,
          search: s,
          roles: role,
        }));
      }),
    );
  }

  updateUser(updatedUser: TeacherInterface, params: any): Observable<any> {
    return this.httpClient.patch('{api}/panel/users/' + updatedUser.id, params).pipe(
      tap(() => {
        this.ctx.setState(
          patch({
            users: updateItem<TeacherInterface>((user) => user.id === updatedUser.id, {
              ...updatedUser,
              ...params,
            }),
          }),
        );
      }),
    );
  }
}
